/* You can add global styles to this file, and also import other style files */
/* body{
    --navbar-scroll: #242424; 
    --navbar-scroll-text: #242424;
    --navbar-scroll-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.20);
}
.navbar {
    background-color: var(--navbar-scroll);
    transition: background-color 1s ease;
    color: var(--navbar-scroll-text);
    -webkit-box-shadow: var(--navbar-scroll-shadow);
    box-shadow: var(--navbar-scroll-shadow);
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
html {
    font-family: 'Poppins', sans-serif;
    background-color: #F9F9F9;
}
body{
    --navbar-scroll: transparent; 
    --navbar-scroll-text: white;
    --navbar-scroll-shadow: none;
}
.navbar {
    background-color: var(--navbar-scroll);
    transition: background-color 1s ease;
    color: var(--navbar-scroll-text);
    -webkit-box-shadow: var(--navbar-scroll-shadow);
    box-shadow: var(--navbar-scroll-shadow);
    overflow-y: hidden;
    overflow-x: hidden;
    /* background: rgba(255, 255, 255, 0.01); */
    backdrop-filter: blur(50px);
}
.nc {
    width: 95%;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
.nav-link {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.nav-link:hover {
    color: #0085FF;
}
.nav-link:active {
    color: #0085FF;
    text-decoration: underline;
}
.wdBtn {
    border: 1px solid #0085FF;
    border-radius: 0px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 141px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.wdBtn:hover {
    color: #0085FF;
    border: 1px solid #0085FF;
    cursor: pointer;
}
.wdBtn:active {
    color: #FFF;
    background-color: #0085FF;
    border: 1px solid #0085FF;
}
a {
    cursor: pointer;
}
.n1-1 {
    width: 85px;
    height: 85px;
}
.navbar-toggler {
    /* width: 10px !important; */
    /* height:10px !important; */
    /* background-color: red; */
    border: none;
    /* color: white; */

  }
  .navbar-toggler>.navbar-toggler-icon {
    /* width: 24px !important; */
    /* height: 10px; */
    /* color: white; */
    /* background-color: white; */
    /* font-weight: 700; */
}
.f1-2-t {
    width: 22px;
    height: 22px;
    margin-top: 6.5px;
}

@media screen and (max-width:840px) {
    .navbar {
        /* height: 100%; */
        /* background-color: #242424; */
        /* position: absolute; */
        /* background: transparent; */
    }
    .navbar-collapse {
        /* background-color: #242424; */
        /* height: 100%; */
    }
    .n1-1 {
        width:  65px;
        height: 65px;
    }
    .nav-link {
        font-size: 16px;
    }
    .wdBtn {
        width: 128px;
        font-size: 14px;
        height: 33px;
    }
    .f1-2-t {
        width: 18px;
        height: 16px;
        margin-top: 3px;
    }
}
@media screen and (max-width:500px) {
    .navbar {
        /* height: 100%; */
        /* background-color: #242424; */
        /* position: absolute; */
        /* background: transparent; */
    }
    .navbar-collapse {
        background-color: #242424;
        /* height: 100%; */
    }
    
    .n1-1 {
        width:  40px;
        height: 40px;
    }
    .nav-link {
        font-size: 12px;
    }
    .wdBtn {
        width: 127px;
        font-size: 12px;
        height: 32px;
        margin-bottom: 9px;
    }
    .f1-2-t {
        width: 18px;
        height: 16px;
        margin-top: 3px;
    }
}